import React from "react";

import Picture from "../Picture";

interface Props {
  book: Book;
}

export default function Book(props: Props) {
  const { author_name, english_title, image, url } = props.book;

  return (
    <a href={url} className="book">
      <div className="cover">
        {image && (
          <figure className="image">
            <Picture image={image} sizes="(max-width: 767px) 50vw, 20vw" />
          </figure>
        )}
      </div>
      <div className="author">{author_name}</div>
      <div className="title" lang="en">
        {english_title}
      </div>
    </a>
  );
}
