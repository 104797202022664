function readyHandler(fn: () => void) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

function maybeClickListener(elem: Element, fn: (evt: MouseEvent) => void) {
  if (elem) {
    elem.addEventListener("click", fn);
  }
}

function setAriaExpanded(elem: Element, value: boolean) {
  elem.setAttribute("aria-expanded", value ? "true" : "false");
}

function mainMenu() {
  let showMenu = false;
  let showSearch = false;

  const menuButton = document.querySelector(".mobile-toggle");
  const searchButton = document.querySelector(".search-toggle a");

  const header = document.querySelector("header");
  const searchInput: HTMLInputElement = document.querySelector(
    "header .search-bar input[type=text]"
  );

  const updateHeader = () => {
    if (header) {
      setAriaExpanded(menuButton, showMenu);
      setAriaExpanded(searchButton, showSearch);
      if (showMenu) {
        header.classList.add("show-menu");
      } else {
        header.classList.remove("show-menu");
      }
      if (showSearch) {
        header.classList.add("show-search");
        if (searchInput) {
          searchInput.focus();
        }
      } else {
        header.classList.remove("show-search");
      }
    }
  };

  const toggleMenu = (evt: Event) => {
    evt.preventDefault();
    showSearch = false;
    showMenu = !showMenu;
    updateHeader();
  };

  const toggleSearch = (evt: Event) => {
    evt.preventDefault();
    showSearch = !showSearch;
    updateHeader();
  };

  maybeClickListener(menuButton, toggleMenu);
  maybeClickListener(searchButton, toggleSearch);
}

function subNavigation() {
  document.querySelectorAll("nav.subnav").forEach((nav: HTMLElement) => {
    const toggleMenu = (evt: MouseEvent) => {
      evt.preventDefault();
      setAriaExpanded(
        evt.currentTarget as Element,
        !nav.classList.contains("show-menu")
      );
      nav.classList.toggle("show-menu");
    };
    maybeClickListener(nav.querySelector(".subnav-toggle"), toggleMenu);
  });
}

export default function navigation() {
  readyHandler(() => {
    mainMenu();
    subNavigation();
  });
}
