import React, { useEffect, useMemo, useRef, useState, RefObject } from "react";
import { uniqueId } from "lodash";

import Book from "./BookCarousel/Book";

interface Props {
  books: Book[];
  title: string;
  strings: Record<string, string>;
}

function useHorizontalScroll(ref: RefObject<HTMLDivElement>) {
  const [width, setWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const elem = ref.current;
    const updateWidth = () => {
      if (elem) {
        setWidth(elem.getBoundingClientRect().width);
        setScrollWidth(elem.scrollWidth);
      }
    };

    const updateScroll = () => {
      if (elem) {
        setScrollLeft(elem.scrollLeft);
      }
    };

    updateWidth();
    updateScroll();
    window.addEventListener("resize", updateWidth);
    window.addEventListener("resize", updateScroll);
    if (elem) {
      elem.addEventListener("scroll", updateScroll);
    }
    return () => {
      window.removeEventListener("resize", updateWidth);
      window.removeEventListener("resize", updateScroll);
      if (elem) {
        elem.removeEventListener("scroll", updateScroll);
      }
    };
  }, [ref]);

  return [width, scrollWidth, scrollLeft];
}

export default function BookCarousel(props: Props) {
  const { books, strings, title } = props;

  const wrapper = useRef<HTMLDivElement>();
  const [width, scrollWidth, scrollLeft] = useHorizontalScroll(wrapper);
  const scrollRight = scrollWidth - (scrollLeft + width);
  const scrollDistance = width * 0.66;

  const showControls = scrollWidth > width;
  const id = useMemo(() => uniqueId("carousel-"), []);

  const scrollBy = (amount: number) => {
    if (wrapper.current) {
      wrapper.current.scrollTo({
        top: 0,
        left: wrapper.current.scrollLeft + amount,
        behavior: "smooth"
      });
    }
  };

  const scrollBackward = () => {
    scrollBy(0 - scrollDistance);
  };

  const scrollForward = () => {
    scrollBy(scrollDistance);
  };

  return (
    <div className="book-carousel">
      <div className="name">
        <h3>{title}</h3>
        {showControls && (
          <div className="controls">
            <button
              aria-controls={id}
              className="previous"
              onClick={scrollBackward}
              disabled={scrollLeft < 10}>
              {strings.previous}
            </button>
            <button
              aria-controls={id}
              className="next"
              onClick={scrollForward}
              disabled={scrollRight < 10}>
              {strings.next}
            </button>
          </div>
        )}
      </div>
      <div id={id} className="books-wrapper" ref={wrapper}>
        <div className="books">
          {books.map((b) => (
            <Book key={b.id} book={b} />
          ))}
        </div>
      </div>
    </div>
  );
}
